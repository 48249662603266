import { useEffect, useState } from 'react'
import axiosInstance from "../../../utils/axiosInstance"
import './DeleteLink.css'

const DeleteLink = () => {
    const [links,setLinks] = useState([])
    const [deleted,setDeleted] = useState(true)


    useEffect(() => {
        axiosInstance.get("/users_links").then(res => {
            setLinks(res.data.data)
        })
    }, [])

    const deleteLink = (id) => {
        axiosInstance.post("/delete_link", {id: id}).then(res => {
            setLinks(res.data.data)
            setDeleted(true)
        })
    }

    return (
        <div>
            {links.map((el, i) => (
                <div className='action_block' key={i}>
                    <div>
                        <span>Domain: {el.origin}</span>
                        <br />
                        <span>Actions: {el.actions.map((elem, ind) => (
                            <div key={ind}>
                                <span>{elem.text} - {elem.action}</span>
                            </div>
                        ))}</span>
                        <button onClick={() => deleteLink(el.id)} className='delete_action'>Delete</button>
                    </div>
                </div>
            ))}
            {deleted && 
                <div className='deleted_red'>
                    Deleted
                </div>
            }
        </div>
    )
}

export default DeleteLink 