import io from 'socket.io-client'

let debug = false
//const url = "https://panel.fluid-pay.com"
const url = debug ? "http://localhost:8003" : window.location.origin
const socket = io(url, {
    transports: ["websocket", "polling"]
})

export default socket
