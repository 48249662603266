import { useState } from "react"
import './Messages.css'


const Messages = ({ messages, id }) => {
    const [showMore, setShowMore] = useState(false)


    return (
        <>
        <div className={`${showMore ? 'less_more' : 'show_more_shadow'}`}>
            {messages.slice(0, showMore ? messages.length : 3).map(elem => (
                <div className={`${elem.senderId == id ? 'guest_message' : 'my_message'} `}>
                    {elem.senderId == id
                        ?
                        <div className="break_all mt-5">
                            <span className="f-bold mr-5">Guest:</span>
                            <span>{elem.message}</span>
                        </div>
                        :

                        <div className="break_all mt-5">
                            <span className="f-bold blue mr-5">Me:</span>
                            <span>{elem.message}</span>
                        </div>
                    }
                </div>
            ))}
            </div>

            <div className="show_more_text" onClick={() => setShowMore(prev => !prev)}>
                <span>{showMore ? 'Show less' : 'Show more'}</span>
            </div>
        </>
    )
}

export default Messages