import { useState } from "react"
import socket from "../../../socket/socket"


const Bottom = ({id}) => {
    const [message,setMessage] = useState('')

    const addMessage = (socketId) => {
        socket.emit("add_message_to_chat", {
            message: message,
            socketId: socketId,
        })
        socket.emit("get_messages_from_chat", {
            socketId: id
        })
    }

    const onInputChange = (e) => {
        setMessage(e.target.value)
    }
    

    return (
        <>
            <input type="text" className="mr-5 send_input add_new_member_input" placeholder="Send message" value={message} onChange={e => onInputChange(e)} />
            <button className="add_button" onClick={() => addMessage(id)}>Send</button>
        </>
    )
}

export default Bottom