import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import './App.css';
import Main from './components/Main/Main';
import MainLayout from './layouts/MainLayout/MainLayout';
import Chat from './components/Chat/Chat';
import Data from './components/Data/Data';
import Admin from './components/Admin/Admin';
import Login from './components/Auth/Login';
import AllowedWebsites from './components/Admin/AllowedWebsites/AllowedWebsites';
import AddNewMember from './components/Admin/AddNewMember/AddNewMember';
import DeleteMember from './components/Admin/DeleteMember/DeleteMember';
import Members from './components/Admin/Members/Members';
import AddLink from './components/Admin/AddLink/AddLink';
import DeleteLink from './components/Admin/DeleteLink/DeleteLink';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<MainLayout />}>
          <Route path='/users/:link' element={<Main />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/storage' element={<Data />} />
        </Route>
        <Route path='/admin' element={<Admin />}>
            <Route path='/admin' element={<Members />} />
            <Route path='/admin/allowed_websites' element={<AllowedWebsites />} />
            <Route path='/admin/add_new_member' element={<AddNewMember />} />
            <Route path='/admin/delete_member' element={<DeleteMember />} />
            <Route path='/admin/add_links' element={<AddLink />} />
            <Route path='/admin/delete_links' element={<DeleteLink />} />
          </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
