import { Outlet, useNavigate } from "react-router-dom"
import Sidebar from "../../components/Sidebar/Sidebar"
import { useEffect, useState } from "react"
import axiosInstance from "../../utils/axiosInstance"
import Navbar from "../../components/Admin/Navbar/Navbar"



const MainLayout = () => {
    const [auth,setAuth] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if(!auth){
            axiosInstance.post('/login').then(res => {
                console.log(res.data)
            }).catch(err => {
                navigate("/login")
            })
        }
        
    }, [auth])

    // useEffect(() => {
    //     if(!auth){
    //         navigate('/login')
    //     }
    // }, [auth])


    return (
        <div className="flex">
            <Sidebar />
            <div className="max_w_1400_navbar">
                <Navbar />
                <Outlet />
            </div>
        </div>
    )
}

export default MainLayout