import { useEffect, useState } from 'react'
import axiosInstance from '../../../utils/axiosInstance'
import './DeleteMember.css'
import Member from './Member/Member'

const DeleteMember = () => {
    const [members,setMembers] = useState([])

    useEffect(() => {
        axiosInstance.get('/members').then(res => {
            setMembers(res.data.data)
        })
    }, [])




    return (
        <div>
            <div className='flex flex_direction'>
                {members.map((el, i) => (
                    <Member key={i} id={el.id} name={el.member_name} setMembers={setMembers} me={el.me} />
                ))}
            </div>
        </div>
    )
}

export default DeleteMember