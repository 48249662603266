import { useState } from 'react'
import axiosInstance from '../../../../utils/axiosInstance'

const Member = ({ id, name,setMembers,me }) => {
    const [successDelete, setSuccessDelete] = useState('')

    const deleteMember = (id) => {
        axiosInstance.post('/delete_member', {id: id}).then(res => {
            setSuccessDelete(res.data.message)
            setMembers(res.data.data)
        })
    }

    return (
        <div className='mb-10'>
            <div>
                <span>ID: </span>
                <span className='mr-5'>{id}</span>
                <span className='f-bold'>{me && "Me"}</span>
            </div>
            <div className='mb-10'>
                <span>Name: </span>
                <span>{name}</span>
            </div>
            <button className='delete_member_button' onClick={() => deleteMember(id)}>Delete member</button>
            {successDelete && 
                <div className='success_message'>
                    <span>{successDelete}</span>
                </div>
            }
        </div>
    )
}

export default Member