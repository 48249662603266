import { useEffect, useState } from "react"
import Navbar from "./Navbar/Navbar"
import { Outlet, useNavigate } from "react-router-dom"
import axiosInstance from "../../utils/axiosInstance"
import Sidebar from "../Sidebar/Sidebar"



const Admin = () => {
    const [auth,setAuth] = useState(false)
    const navigate = useNavigate()


    // useEffect(() => {
    //     if(!auth){
    //         navigate('/login')
    //     }
    // }, [auth])
    useEffect(() => {
        axiosInstance.get('/is_admin').then(res => {
            if(res.status == 200){
                console.log("You are admin")
            }
        }).catch(err => {
            if(err.response.status == 403){
                navigate('/')
            }
        })
    }, [])

    return (
        <div className="flex">
            <Sidebar />
            <div className="max_w_1400_navbar">
                <Navbar />
                <Outlet />
            </div>
        </div>
    )
}

export default Admin