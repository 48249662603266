import { useState } from 'react'
import axiosInstance from '../../utils/axiosInstance'
import './Login.css'
import socket from '../../socket/socket'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const [userInputData, setUserInputData] = useState({
        member_name: '',
        member_pass: '',
    })
    const navigate = useNavigate()
    const login = () => {
        axiosInstance.post('/login', userInputData).then(res=> {
            if(res.status == 200){
                socket.emit("member:login", "")
                navigate("/")
            }
        })
    }

    const onChange = (e) => {
        setUserInputData(prev => ({
            ...prev,
            [e.target.name]:e.target.value 
        }))
    }


    return (
        <div className="login_flex">
            <div className="authForm">
            <input type="text" name='member_name' placeholder='Username' className='login_input' value={userInputData.member_name} onChange={(e) => onChange(e)} />
            <input type="password" name='member_pass' placeholder='Password' className='login_input' value={userInputData.member_pass} onChange={(e) => onChange(e)} />
            <button onClick={login} className='login_btn'>Login</button>
            </div>
        </div>
    )
}

export default Login