import { useEffect, useState } from 'react'
import './AllowedWebsites.css'
import axiosInstance from '../../../utils/axiosInstance'
import closeIcon from '../../../assets/images/close.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const AllowedWebsites = () => {
    const [allowedWebsites,setAllowedWebsites] = useState([])
    const [url, setUrl] = useState('')

    useEffect(() => {
        axiosInstance.get('/allowed_website').then(res => {
            setAllowedWebsites(res.data.data)
        })
    }, [])

    const addWebsite = () => {
        axiosInstance.post('/add_new_website', {url}).then(res => {
            console.log(res.data)
            setAllowedWebsites(res.data.data)
        })
    }
    console.log(allowedWebsites)

    const deleteWebsite = (url) => {
        axiosInstance.post('/delete_website', {url: url}).then(res => {
            setAllowedWebsites(res.data.data)
        })
        axiosInstance.post('/delete_website', {url: url}).then(res => {
            setAllowedWebsites(res.data.data)
        })
    }

    return (
        <div>
            <div className='add_new_website'>
                <span className='mr-10'>Add new website: </span>
                <input value={url} onChange={e => setUrl(e.target.value)} type="text" className='new_website_input mr-10' placeholder='Url' />
                <button onClick={addWebsite} className='add_button'>Add <FontAwesomeIcon icon={faPlus} className='plusIcon' /></button>
            </div>
            <div className='flex flex-wrap'>
                <span>Allowed websites: </span>
                {allowedWebsites.map((el, i) => (
                    <div className='allowed_website aic'>
                        <span>{el}</span>
                        <img onClick={() => deleteWebsite(el)} className='close_icon' src={closeIcon} alt="" />
                    </div>
                ))}
            </div>    
            <div className='flex mt-20'>
                <div className='warningBlock'>
                <span className='warning_red mr-10'><FontAwesomeIcon icon={faWarning} /></span>
                <span>After adding you will need to restart this script in your server for allowing website</span>
                </div>
            </div>
        </div>
    )
}

export default AllowedWebsites