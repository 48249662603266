import { useLocation } from "react-router-dom"



const CurrentPath = () => {
    const location  = useLocation()
    return (
        <h1>{location.pathname.slice(1,2).toUpperCase() + location.pathname.slice(2, location.pathname.length)}</h1>
    )
}

export default CurrentPath