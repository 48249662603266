import { NavLink, useLocation } from "react-router-dom"
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faUserMinus } from '@fortawesome/free-solid-svg-icons';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {

    const location = useLocation()
    const links = [
        {
            text: "Allowed Websites",
            path: '/admin/allowed_websites',
            icon: <FontAwesomeIcon icon={faSitemap} className='navbarIcons'/>
        },
        {
            text: "Add New Member",
            path: '/admin/add_new_member',
            icon: <FontAwesomeIcon icon={faUserPlus} className='navbarIcons'/>
        },
        {
            text: "Delete Members",
            path: '/admin/delete_member',
            icon: <FontAwesomeIcon icon={faUserMinus} className='navbarIcons'/>
        },
        {
            text: "Add Links",
            path: '/admin/add_links',
            icon: <FontAwesomeIcon icon={faLink} className='navbarIcons'/>
        },
        {
            text: "Delete Links",
            path: '/admin/delete_links',
            icon: <FontAwesomeIcon icon={faLinkSlash} className='navbarIcons'/>
        },
    ]

    return (
        <div className="max_w_1400_navbar">
            <div className="navbarButtons">
            {links.map(el => (
                <NavLink to={el.path} className={`navbar_link ${el.path == location.pathname && 'active_nav_link'}`}>
                    {el.icon} {el.text}
                </NavLink>
            ))}
            </div>
        </div>
    )
}


export default Navbar