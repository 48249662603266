import { useEffect, useState } from 'react'
import './Members.css'
import axiosInstance from '../../../utils/axiosInstance'

const Members = () => {
    const [members,setMembers] = useState([])

    useEffect(() => {
        axiosInstance.get('/members/full').then(res => {
            setMembers(res.data.data)
        })
    }, [])

    return (
        <div>
            <div className='flex membersCountDiv'>
                <span className='f-bold mr-5'>Members count:</span>
                <span className='count'>{members.length}</span>
            </div>
        </div>
    )
}

export default Members