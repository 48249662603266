import { useState } from 'react'
import './AddLink.css'
import axiosInstance from '../../../utils/axiosInstance'

const AddLink = () => {
    const [actions,setActions] = useState([])
    const [domainLink,setDomainLink] = useState("")
    const [text,setText] = useState("")
    const [action,setAction] = useState("")
    const [linkAdded, setLinkAdded] = useState(false)

    const addAction = () => {
        setActions(prevArr => [...prevArr, {
            text,
            action
        }])
    }
    const deleteAction = (index) => {
        setActions(prevArr => prevArr.filter((el,i) => index !== i))
        }
    const createLink = () => {
        axiosInstance.post("/add_link", {
            link: domainLink,
            actions: actions
        }).then(res => {
            setLinkAdded(true)
        })
    }

    return (
        <div className='addLinkContent'>
            <input type="text" className='linkInput' value={domainLink} onChange={e => setDomainLink(e.target.value)}
                   placeholder="Domain: https://domain.com"/>

            <div className="flex">
                <input className="linkContentInputs" value={text} onChange={e => setText(e.target.value)} type="text"
                       placeholder="Text"/>
                <input className="linkContentInputs" value={action} onChange={e => setAction(e.target.value)}
                       type="text" placeholder="Action"/>
            </div>
            <div className='addLinkContentAddAction'>
                <button className="mt-5 ml-5 create_member" onClick={addAction}>Add Action</button>
            </div>
            {actions.map((el, i) => (
                <div key={i} className='action_block'>
                    <div className='action'>Action Text: {el.text}</div>
                    <div className='action'>Action Value: {el.action}</div>
                    <button onClick={() => deleteAction(i)} className='delete_action'>Delete action</button>
                </div>
            ))}
            <div className="add_link mt-5">
                <button onClick={createLink}>Add Link</button>
            </div>
            {linkAdded &&
                <div className='link_added'>
                    <span>Link added</span>
                </div>
            }
        </div>
    )
}

export default AddLink