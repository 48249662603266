import './Sidebar.css'
import userIcon from '../../assets/images/user_icon.png'
import storageIcon from '../../assets/images/storage_icon.png'
import chatIcon from '../../assets/images/chat_icon.png'
import adminIcon from '../../assets/images/admin.png'
import useSound from 'use-sound';
import notifSfx from './notif.mp3';
import { NavLink, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import socket from '../../socket/socket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../utils/axiosInstance'
const Sidebar = () => {
    const [admin,setAdmin] = useState()
    const [openedSidebar, setOpenedSidebar] = useState(false)
    const [play] = useSound(notifSfx);
    const [usersNotifications, setUsersNotifications] = useState(0)
    const [chatNotifcations, setChatNotifications] = useState(0)
    const location = useLocation()
    const [allSitesLinks, setAllSitesLinks] = useState([])
    const [showLinks,setShowLinks] = useState(false)
    const links = [
        {
            icon: adminIcon,
            path: '/admin',
            text: "Admin",
            notifications: 0
        },
        {
            icon: <FontAwesomeIcon icon={faUser} />,
            path: '/users',
            text: "Users",
            notifications: usersNotifications
        },
    ]

    useEffect(() => {
        axiosInstance.get("/users_links").then(res => {
            setAllSitesLinks(res.data.data)
        })
    }, [])

    useEffect(() => {
        socket.on("added_notification_users", data => {
            play()
            setUsersNotifications(usersNotifications + 1)
            allSitesLinks.map((e, i) => {
                if(data.includes(e.origin) && !window.location.href.includes(e.origin)){
                    allSitesLinks[i].joinNotif = 1
                }
            });
        })
    }, [socket,usersNotifications,allSitesLinks])
    useEffect(() => {
        socket.on("added_notification_chat", data => {
            if(location.pathname != "/chat"){
                setChatNotifications(chatNotifcations + data)
            }
        })
    }, [socket,location.pathname,chatNotifcations])
    useEffect(() => {
        if(location.pathname == "/users"){
            setUsersNotifications(0)
        }
        if(location.pathname == "/chat"){
            setChatNotifications(0)
        }
    }, [location.pathname])
    useEffect(() => {
        socket.on("send_changed_data", data => {
            allSitesLinks.map((e, i) => {
                if(data.link.includes(e.origin) && !window.location.href.includes(e.origin)){
                    allSitesLinks[i].notif = 1
                }
            });
        })
    }, [socket, allSitesLinks])

    useEffect(() => {
        allSitesLinks.map((e, i) => {
            if(window.location.href.includes(e.origin)){
                allSitesLinks[i].notif = 0
            }
        });
    }, [allSitesLinks,window.location.href])

    useEffect(() => {
        axiosInstance.get('/is_admin').then(res => {
            if(res.status == 200){
                setAdmin(true)
            }
        }).catch(err => {
            if(err.response.status == 403){
                setAdmin(false)
            }
        })
    }, [])

    const sidebarAction = (bool) => {
        setOpenedSidebar(bool)
    }

    const showLinksListener = (bool) => {
        setShowLinks(bool)
    }

    return (
        <div className={`flex flex_direction ${openedSidebar ? "sidebar_opened" : "sidebar_closed"}`} onMouseLeave={() => sidebarAction(false)} onMouseEnter={() => sidebarAction(true)}>
            <div className='icons-margin'>
                {links.slice(admin ? 0 : 1).map((el,i) => (
                    <div>
                    {el.path == "/users" ? 
                    <div  className='user cp flex mb-20 spec_link' style={{width: `${openedSidebar ? "100%" : "35px"}`}} >
                        <div className='prelative h100'>
                            <div className="sideBarIcon">{i == 0 ? <img className='image-admin' src={el.icon} /> : el.icon}</div>
                            {el.notifications !== 0 &&
                            <div className='notification_round absolute'>
                                <span>{Math.round(el.notifications)}</span>
                            </div>
                            }
                            <div className='users prelative mb-10'>
                                {allSitesLinks.map((elem,i) => (
                                    <div className='mb_class'>
                                    <NavLink to={`/users/${elem.origin}`} key={i} className='user specific_link'>
                                        {elem.notif == 1 && <div className='absolute_round'></div>}
                                        {elem.joinNotif == 1 && <div className='absolute_round_blue'></div>}
                                        <span>{elem.origin}</span>
                                    </NavLink>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                        {openedSidebar ?
                        <div className='pabsolute opacity-1 transition-1000 w-100px'> 
                            <span>{el.text}</span>
                        </div>
                        :
                        <div className='pabsolute opacity-0 transition-800 w-100px'> 
                            <span>{el.text}</span>
                        </div>
                        }
                    </div>
                        :
                    <NavLink to={`${el.path}`} className='user cp flex mb-20'>
                        <div className='prelative h100'>
                            <div className="sideBarIcon">{i == 0 ? <img className='image-admin' src={el.icon} /> : el.icon}</div>
                            {el.notifications !== 0 &&
                            <div className='notification_round absolute'>
                                <span>{Math.round(el.notifications)}</span>
                            </div>
                            }
                        </div>
                        {openedSidebar ?
                        <div className='opacity-1 transition-1000 w-100px'> 
                            <span>{el.text}</span>
                        </div>
                        :
                        <div className='opacity-0 transition-800 w-100px'> 
                            <span>{el.text}</span>
                        </div>
                        }
                    </NavLink>
                    }
                    </div>

                ))}
            </div>
        </div>
    )
}

export default Sidebar