import { useEffect, useState } from "react"
import socket from "../../socket/socket"
import './Chat.css'
import Bottom from "./Bottom/Bottom"
import CurrentPath from "../CurrentPath/CurrentPath"
import Messages from "./Messages/Messages"
import axiosInstance from "../../utils/axiosInstance"

const Chat = () => {
    const [messages,setMessages] = useState([])
    
    useEffect(() => {
        socket.emit("connect_to_chat_admin")
    }, [socket])
    useEffect(() => {
        socket.emit("get_all_messages_admin")
    }, [socket])

    useEffect(() => {
        socket.on("get_chat_messages_admin", data => {
            setMessages(removeDuplicates(data))
        })
    }, [socket,messages])

    useEffect(() => {
        socket.on("send_chat_connections", data => {
            setMessages(removeDuplicates(data))
        })
    }, [socket,messages])
    useEffect(() => {
        socket.on("user_disconnected_chat", data => {
            setMessages(removeDuplicates(data))
        })
    }, [socket,messages])
    useEffect(() => {
        socket.on("added_new_message", data => {
            setMessages(removeDuplicates(data))
        })
    }, [socket,messages])
    useEffect(() => {
        socket.on("deleted_conversation", data => {
            setMessages(removeDuplicates(data))
        })
    }, [socket,messages])
    function removeDuplicates(arr) {
        return arr.filter((ele, ind) => ind === arr.findIndex(elem => elem.id === ele.id))
    }

    const deleteConversation = (socketId) => {
        socket.emit("delete_conversation", socketId)
    }

    const saveConversation = (data) => {
        axiosInstance.post("/add_logs/true", {data}).then(res => {
            console.log(res.data)
        })
    }

    console.log(messages)
    return (
        <div className="flex flex_direction aic w-100">
        <div className="flex jcc">
            <CurrentPath />
        </div>
        <div className="chat_flex max_w_1200 ml-100">
            {messages.map(el => (
                <div className="message flex flex_direction mr-20 mb-20">
                    <div className="flex">
                    {el.messages.length !== 0 && el.messages.at(-1).senderId == el.id && <span className="action_information_orange padding-10">Wait for message</span>}
                    </div>
                    <div className="flex aic">
                        <span className="f-bold mr-5">ID:</span>
                        <span className="mr-5">{el.id}</span>
                        {el.destroyed && <div className="bg_red white bordered-12 padding-10">Disconnected</div>}
                    </div>
                    <div className="flex aic">
                        <span className="f-bold mr-5">Time:</span>
                        <span className="mr-5">{el.time}</span>
                    </div>
                    <div className="flex aic">
                        <span className="f-bold mr-5">Ip:</span>
                        <span className="mr-5">{el.ip}</span>
                    </div>
                    <div className="flex aic">
                        <span className="f-bold mr-5">Host:</span>
                        <span className="mr-5">{el.requesterUrl}</span>
                    </div>
                    <div>
                        
                        <Messages messages={el.messages} id={el.id} />
                        {el.onTyping && 
                            <div className="break_all mt-5">
                                <span>Guest Is typing...</span>
                            </div>
                        }
                    </div>
                    <div className="flex mt-20">
                        <Bottom id={el.id} />
                    </div>
                    <div>
                        <div onClick={() => saveConversation(el)} className="btn_bg_green  white flex jcc mt-20 mb-10 bordered-12 cp padding-10">Save</div>
                        <div onClick={() => deleteConversation(el.id)} className="btn_bg_red  white flex jcc mt-20 mb-10 bordered-12 cp padding-10">Delete</div>
                    </div>
                </div>
            ))}
        </div>
        </div>
    )
}

export default Chat