import { useState } from 'react'
import './AddNewMember.css'
import axiosInstance from '../../../utils/axiosInstance'

const AddNewMember = () => {
    const [infoData, setInfoData] = useState({
        name: '',
        password: '',
        role: '1',
    })
    const [successMessage, setSuccessMessage] = useState('')


    const onDefaultDataChange = (e) => {
        setInfoData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }


    const createMember = () => {
        axiosInstance.post('/add_new_member', infoData).then(res => {
            setSuccessMessage(res.data.message)
        })
    }

    console.log(infoData)

    return (
        <div className='addMemberBlock'>
            <div className='flex flex_direction'>
                <div className='flex aic mt-10 mb-10'>
                    <span className='mr-10'>Login: </span>
                    <input name='name' value={infoData.name} onChange={e => onDefaultDataChange(e)} className='add_new_member_input' type="text" placeholder='Login' />
                </div>
                <div className='flex aic mt-10 mb-10'>
                    <span className='mr-10'>Password: </span>
                    <input name='password' value={infoData.password} onChange={e => onDefaultDataChange(e)} className='add_new_member_input' type="text" placeholder='Password' />
                </div>
                <div className='flex aic mt-10 mb-10'>
                    <span className='mr-10'>Role</span>
                    <select name='role' className='roleSelect' defaultValue={"1"} onChange={(e) => onDefaultDataChange(e)}>
                        <option value="1">User</option>
                        <option value="3">Admin</option>
                    </select>
                </div>
                <div>
                    <button className='create_member' onClick={createMember}>Create member</button>
                </div>
                <div>
                    {successMessage && (
                        <div className='success_message'>
                            <span>{successMessage}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddNewMember